<template>
    <Layout>
        <h4>Reference Number : {{ details.ref_no }}</h4>
        <b-overlay :show="show" rounded="sm">
            <div class="row">
                <div class="col-lg-7">
                    <b-tabs
                        active-nav-item-class="font-weight-bold text-uppercase text-primary"
                        active-tab-class="font-weight-bold text-success"
                        content-class="mt-3"
                    >
                        <b-tab title="Request Details" active>
                            <div>
                                <p>
                                    <span class="title"
                                        >Type Of Alteration :</span
                                    >
                                    &nbsp;&nbsp;{{ details.type }}
                                </p>

                                <p>
                                    <span class="title"> Details :</span>
                                    &nbsp;&nbsp;{{ details.details }}
                                </p>
                                <p>
                                    <span class="title"> Date :</span>
                                    &nbsp;&nbsp;{{ details.date }}
                                </p>
                                <p>
                                    <span class="title">
                                        Contractor Name :</span
                                    >
                                    &nbsp;&nbsp;{{ details.contractor_name }}
                                </p>
                                <p>
                                    <span class="title">
                                        Contractor Person Name :</span
                                    >
                                    &nbsp;&nbsp;{{
                                        details.contact_person_name
                                    }}
                                </p>
                                <p>
                                    <span class="title"> Office Number :</span>
                                    &nbsp;&nbsp;{{
                                        details.contact_person_office_no
                                    }}
                                </p>
                                <p>
                                    <span class="title"> Mobile Number :</span>
                                    &nbsp;&nbsp;{{
                                        details.contact_person_mobile_no
                                    }}
                                </p>
                                <p>
                                    <span class="title"> Fax Number :</span>
                                    &nbsp;&nbsp;{{
                                        details.contact_person_fax_no
                                    }}
                                </p>
                                <div
                                    v-for="status in requestStatus"
                                    :key="status.id"
                                >
                                    <p
                                        v-if="status.id === details.status"
                                        :class="status.text"
                                    >
                                        <span class="title"> Status :</span>
                                        &nbsp;&nbsp;
                                        {{ status.text }}
                                    </p>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab title="Contact Information">
                            <div>
                                <p>
                                    <span class="title">Unit Number :</span>
                                    &nbsp;&nbsp;{{ details.unit_no }}
                                </p>
                                <p>
                                    <span class="title"> Name :</span>
                                    &nbsp;&nbsp;{{ details.user_name }}
                                </p>
                                <p>
                                    <span class="title"> Email :</span>
                                    &nbsp;&nbsp;{{ details.email }}
                                </p>
                                <p>
                                    <span class="title"> Nationality :</span>
                                    &nbsp;&nbsp;{{ details.nationality }}
                                </p>
                                <p>
                                    <span class="title"> PO Box Number :</span>
                                    &nbsp;&nbsp;{{ details.po_box }}
                                </p>
                                <p>
                                    <span class="title">
                                        Passport Number :</span
                                    >
                                    &nbsp;&nbsp;{{ details.passport_no }}
                                </p>
                                <p>
                                    <span class="title"> Mobile :</span>
                                    &nbsp;&nbsp;{{ details.mobile }}
                                </p>

                                <div class="row">
                                    <div class="col-lg-4">
                                        <p>
                                            Passport&nbsp;&nbsp;:&nbsp;&nbsp;
                                            <b-button
                                                @click="
                                                    viewDocument(
                                                        details.passport
                                                    )
                                                "
                                                variant="primary"
                                            >
                                                View
                                            </b-button>
                                        </p>
                                    </div>
                                    <div class="col-lg-4">
                                        <p>
                                            Emirates Id&nbsp;&nbsp;:&nbsp;&nbsp;

                                            <b-button
                                                @click="
                                                    viewDocument(details.eid)
                                                "
                                                variant="primary"
                                            >
                                                View
                                            </b-button>
                                        </p>
                                    </div>
                                    <div class="col-lg-4">
                                        <p>
                                            Visa Copy&nbsp;&nbsp;:&nbsp;&nbsp;
                                            <b-button
                                                @click="
                                                    viewDocument(details.visa)
                                                "
                                                variant="primary"
                                            >
                                                View
                                            </b-button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </b-tab>

                        <b-tab title="Documents">
                            <div class="documents">
                                <p>
                                    Trade License&nbsp;&nbsp;:&nbsp;&nbsp;
                                    <b-button
                                        @click="
                                            viewDocument(details.trade_licence)
                                        "
                                        variant="primary"
                                    >
                                        View
                                    </b-button>
                                </p>

                                <p>
                                    Scope Of Work&nbsp;&nbsp;:&nbsp;&nbsp;
                                    <b-button
                                        @click="
                                            viewDocument(details.scope_of_work)
                                        "
                                        variant="primary"
                                    >
                                        View
                                    </b-button>
                                </p>

                                <p>
                                    Drawings&nbsp;&nbsp;:&nbsp;&nbsp;
                                    <b-button
                                        @click="viewDocument(details.drawings)"
                                        variant="primary"
                                    >
                                        View
                                    </b-button>
                                </p>

                                <p>
                                    Letter Of Agreement&nbsp;&nbsp;:&nbsp;&nbsp;
                                    <b-button
                                        @click="
                                            viewDocument(
                                                details.letter_of_agreement
                                            )
                                        "
                                        variant="primary"
                                    >
                                        View
                                    </b-button>
                                </p>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
                <div class="col-lg-5">
                    <auditManagementApproval
                        :details="details"
                        :requestType="requestType"
                        @refreshContent="refetchItem"
                    />
                    <br />
                    <addComment
                        :details="details"
                        :requestType="requestType"
                        @refreshContent="refetchItem"
                    />

                    <addRejectComment
                        :details="details"
                        :requestType="requestType"
                        @refreshContent="refetchItem"
                    />

                    <workFlowDiagram
                        :workAssigned="workAssigned"
                        :details="details"
                    />
                </div>
            </div>

            <engineeringApproval
                :details="details"
                :type="type"
                @refreshContent="refetchItem"
            />
            <br />

            <engineeringFeedBack
                :details="details"
                :type="commentType"
                :url="url"
                @refreshContent="refetchItem"
            />
            <br />

            <facilityManagementApproval
                :details="details"
                :type="type"
                @refreshContent="refetchItem"
                :url="`homeimprovements/home-and-commercial-approval-update`"
            />
            <br />

            <accountsApproval :details="details" :requestType="requestType" />

            <br />
            <updateRequestStatus
                :details="details"
                :requestType="requestType"
            />
            <br />
        </b-overlay>
    </Layout>
</template>
<script>
/* eslint-disable no-unused-vars */

import Layout from "../../layouts/main.vue";
import { BASE_URL, REQUEST_STATUS } from "../../common";
import axios from "axios";
import unitMixin from "../../../mixins/unitMixin";
import addComment from "../requestFlow/addComments.vue";
import addRejectComment from "../requestFlow/addRejectionComments.vue";
import workFlowDiagram from "../requestFlow/workFlow.vue";
import auditManagementApproval from "../requestFlow/audit&ManagementApproval.vue";
import engineeringApproval from "../requestFlow/engineeringDepartmentApproval.vue";
import engineeringFeedBack from "../requestFlow/engineeringFeedback.vue";
import facilityManagementApproval from "../requestFlow/facilityManagementApproval.vue";
import accountsApproval from "../requestFlow/accountsApproval.vue";
import updateRequestStatus from "../requestFlow/statusUpdate.vue";

export default {
    mixins: [unitMixin],
    components: {
        Layout,
        addComment,
        addRejectComment,
        workFlowDiagram,
        auditManagementApproval,
        engineeringApproval,
        engineeringFeedBack,
        facilityManagementApproval,
        accountsApproval,
        updateRequestStatus,
    },
    mounted() {
        console.log(this.$route.params.id);
        this.getDetails(this.$route.params.id);
    },
    data() {
        return {
            url: "homeimprovements/save-engg-request-status-log",
            commentType: "cm",
            type: "CommercialModification",
            requestType: null,
            workAssigned: [],
            requestStatus: REQUEST_STATUS,
            test: "admin",
            show: true,
            details: {},
        };
    },
    methods: {
        refetchItem() {
            this.getDetails(this.$route.params.id);
        },
        getDetails(id) {
            if (localStorage.cs_user_token) {
                axios
                    .get(
                        `${BASE_URL}commercialmodifications/${id}/?token=${localStorage.cs_user_token}`
                    )
                    .then((res) => {
                        console.log(res.data.response);
                        this.show = false;
                        this.details = res.data.response;
                        this.requestType = res.data.request_type;
                        this.workAssigned = res.data.workassigned;
                    });
            }
        },
    },
};
</script>
<style scoped>
.documents {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.title {
    font-weight: bolder;
}
p {
    color: black;
}

.Pending {
    color: #ff635c;
}
.Started {
    color: #ffc71a;
}
.Completed {
    color: #19b699;
}
.Closed {
    color: #777;
}
#awaiting {
    color: #1b3863;
}
</style>
